import ReactDOM from 'react-dom';
import '@app/index.css';
import App from '@app/App';
import reportWebVitals from '@app/reportWebVitals';
import '@app/core/language/i18';
import disableLogs from '@app/disableLogs';
import { StrictMode } from 'react';

disableLogs();
ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
